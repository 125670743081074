/*
 * @Descripttion: 
 * @Author: zhangziyu
 * @Date: 2024-09-03 15:24:39
 * @LastEditors: zhangziyu
 * @LastEditTime: 2024-09-04 10:27:41
 * @Introduce: 
 */
import http from '@/utils/http';

/**
 * @description:  用户反馈提交
 * @param {*} formdata
 * @return {*}
 */
export function feedback(formdata: any): any {
  return http.post(
    `/portal-resources/1/feedBack/add`,
    formdata
  );
}
export function feedBackDetails(id: string, token: string): any {
  return http.get(
    `/portal-resources/1/feedBack/${id}`,
    {},
    {
      headers: {
        Application_name: 'PORTAL',
        PRODUCT_CODE: 'AGENCY_ADMIN',
        Authorization: 'bearer ' + token,
      },
    }
  );
}
<template>
  <div class="feedback">
    <div class="feedback-form">
      <van-form ref="refFeedback" :show-error="false">
        <van-field
          readonly
          required
          clickable
          name="softWareTypeLabel"
          :value="softWareTypeLabel"
          label="软件选择"
          placeholder="请选择反馈软件"
          :rules="[
            {
              required: true,
              message: '请选择反馈软件！',
            },
          ]"
          @click="onClickSoftWareType"
        >
        </van-field>
        <van-field
          name="feedBackType"
          required
          label="反馈类型"
          :rules="[
            {
              required: true,
              message: '请选择反馈类型！',
            },
          ]"
        >
          <template #input>
            <van-radio-group
              v-model="feedBackType"
              direction="horizontal"
              class="radioGroup"
              @change="onChangeFeedBackType"
            >
              <van-radio name="1">功能问题</van-radio>
              <van-radio name="2">界面问题</van-radio>
              <van-radio name="3">体验问题</van-radio>
              <van-radio name="4">兼容性问题</van-radio>
              <van-radio name="5">性能问题</van-radio>
              <van-radio name="6">其他问题</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="detailContent"
          name="detailContent"
          rows="2"
          autosize
          type="textarea"
          maxlength="200"
          show-word-limit
          label="反馈内容"
          placeholder="请输入反馈内容"
          :rules="[
            {
              validator: validatorInfo,
              message: '反馈内容,语音反馈或上传图片至少完成一项！',
            },
          ]"
          @blur="onBlurDetailContent"
        >
        </van-field>
        <van-field
          name="audioFileUrl"
          label="语音反馈"
          :rules="[
            {
              validator: validatorInfo,
              message: '反馈内容,语音反馈或上传图片至少完成一项！',
            },
          ]"
        >
          <template #input>
            <img
              src="../../../assets/img/userFeedback/microphone.png"
              alt=""
              @click="onClickSoundRecord"
            />
            <div class="prompt" v-if="audioFileUrl">
              <div class="small-triangle"></div>
              <div class="prompt-content" @click="handlePlay">
                <img
                  :src="
                    require(soundRecord.recordingstatus === '03'
                      ? '../../../assets/img/userFeedback/soundRecording.gif'
                      : '../../../assets/img/userFeedback/soundRecording.png')
                  "
                  style="width: 15px; height: 20px; margin-right: 5px"
                  alt=""
                />
                <span>语音文件</span>
                <span>
                  {{
                    soundRecord.recorder && soundRecord.recorder.duration >= 0.5
                      ? Math.round(soundRecord.recorder.duration) + "s"
                      : ""
                  }}
                </span>
              </div>
            </div>
            <van-icon
              v-if="audioFileUrl"
              style="margin-left: 10px"
              size="20"
              name="close"
              @click="handleDestroy(false)"
            />
          </template>
        </van-field>
        <van-field
          name="fileUrl"
          label="上传图片"
          :rules="[
            {
              validator: validatorInfo,
              message: '反馈内容,语音反馈或上传图片至少完成一项！',
            },
          ]"
        >
          <template #input>
            <van-uploader
              v-model="fileUrl"
              :before-read="onBeforeRead"
              :after-read="onAfterRead"
              @delete="onDeleteImg"
              multiple
              accept="image/*"
              :max-count="5"
            />
          </template>
        </van-field>
      </van-form>
    </div>
    <van-popup v-model="softWareTypeAction.show" position="bottom">
      <van-picker
        :default-index="defaultIndex"
        value-key="label"
        show-toolbar
        :columns="softWareTypeAction.columns"
        @confirm="onConfirmSoftWareType"
        @cancel="onCancelSoftWareType()"
      />
    </van-popup>
    <van-popup
      position="bottom"
      :style="{ height: '35%' }"
      v-model="soundRecord.show"
      round
      :close-on-click-overlay="false"
      closeable
      close-icon="close"
      @click-close-icon="onCloseSoundRecord"
    >
      <div class="soundRecording">
        <div
          class="container"
          :class="[soundRecord.isItAnimated ? 'container-play' : 'container-stop']"
          ref="containerRef"
        >
          <span style="--d: 7"></span>
          <span style="--d: 6"></span>
          <span style="--d: 5"></span>
          <span style="--d: 4"></span>
          <span style="--d: 3"></span>
          <span style="--d: 2"></span>
          <span style="--d: 1"></span>
          <span style="--d: 0"></span>
          <span style="--d: 1"></span>
          <span style="--d: 2"></span>
          <span style="--d: 3"></span>
          <span style="--d: 4"></span>
          <span style="--d: 5"></span>
          <span style="--d: 6"></span>
          <span style="--d: 7"></span>
        </div>
        <div class="recordingDuration">
          {{
            soundRecord.recorder && soundRecord.recorder.duration.toFixed(2) > 120.0
              ? "120.00"
              : soundRecord.recorder.duration.toFixed(2)
          }}
        </div>
        <div class="soundRecording-action">
          <div class="reRecord">
            <img
              @click="handleDestroy(false)"
              src="@/assets/img/userFeedback/withdraw.png"
              class="reRecordIcon"
              alt=""
            />
            <!-- <van-icon @click="handleDestroy(false)" name="revoke" size="25" class="reRecordIcon"/> -->
            <span>重录</span>
          </div>
          <div class="reRecord">
            <img
              @click="handleStart"
              :src="
                require(`@/assets/img/userFeedback/${
                  soundRecord.recordingstatus === '01' ? 'micIcon-red' : 'micIcon'
                }.png`)
              "
              alt=""
            />
            <span>{{
              soundRecord.recordingstatus === "01" ? "点击结束录音" : "点击开始录音"
            }}</span>
          </div>
          <div class="reRecord">
            <van-loading v-if="soundRecord.uploadRecordLoading" />
            <img
              v-else
              @click="uploadRecord"
              src="@/assets/img/userFeedback/complete.png"
              class="reRecordIcon"
              alt=""
            />
            <span>完成</span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import AliOss from "@/utils/alioss";
import Recorder from "js-audio-recorder";
export default {
  props: {
    feedback: {
      type: Object,
      default: () => {
        return {
          softWareType: "1",
          softWareTypeLabel: "计价软件",
          feedBackType: "1",
          detailContent: "",
          fileUrl: [], // 上传文件
          audioFileUrl: "",
        };
      },
    },
  },
  data() {
    return {
      softWareType: "1",
      softWareTypeLabel: "计价软件",
      feedBackType: "1",
      detailContent: "",
      fileUrl: [], // 上传文件
      audioFileUrl: "",
      uploadfile: {
        type: "",
        name: "",
      }, // 上传单图的情况
      uploadfiles: [], // 上传多图的情况
      softWareTypeAction: {
        show: false,
        columns: [
          {
            label: "材料信息",
            value: "6",
          },
          {
            label: "工程指标",
            value: "5",
          },
          {
            label: "标书检查",
            value: "4",
          },
          {
            label: "智能组价",
            value: "3",
          },
          {
            label: "算量软件",
            value: "2",
          },
          {
            label: "计价软件",
            value: "1",
          },
        ],
      },
      soundRecord: {
        show: false,
        isItAnimated: false, // 是否播放动画
        timer: null, // 录音
        playTime: 0, // 录音时长
        recorder: null,
        recordingstatus: "00", // 00：未开始，01：正在录音，02:停止录音，03：播放录音
        uploadRecordLoading: false,
      },
    };
  },
  computed: {
    defaultIndex() {
      return this.softWareTypeAction.columns.findIndex(
        (item) => item.value === this.softWareType
      );
    },
  },
  created() {
    this.soundRecord.recorder = new Recorder({
      sampleBits: 16, // 采样位数，支持 8 或 16，默认是 16
      sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，Chrome 是 48000
      numChannels: 1, // 声道数，支持 1 或 2， 默认是 1
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("emit-ref", this.$refs.refFeedback);
    });
  },
  watch: {
    "soundRecord.playTime"(newVal) {
      if (Math.round(newVal) === Math.round(this.recorder.duration)) {
        this.soundRecord.recordingstatus = "02";
      }
    },
    "soundRecord.recorder.duration"(val) {
      if (val.toFixed(2) > 120.0) {
        this.handleStop(); // 停止录音
        this.$toast.fail("录制时长已长达2分钟，已自动停止录制！");
      }
    },
    feedback: {
      handler(newVal) {
        this.softWareType = newVal.softWareType;
        this.softWareTypeLabel = newVal.softWareTypeLabel;
        this.feedBackType = newVal.feedBackType;
        this.detailContent = newVal.detailContent;
        this.fileUrl = newVal.fileUrl;
        this.audioFileUrl = newVal.audioFileUrl;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    validatorInfo() {
      if (
        this.detailContent == "" &&
        (this.audioFileUrl ?? "") == "" &&
        this.fileUrl.length === 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    onClickSoftWareType() {
      this.softWareTypeAction.show = true;
    },
    onCancelSoftWareType() {
      this.softWareTypeAction.show = false;
    },
    onConfirmSoftWareType(e) {
      this.softWareTypeAction.show = false;
      let newFeedbackItem = {
        softWareType: e.value,
        softWareTypeLabel: e.label,
        feedBackType: this.feedBackType,
        detailContent: this.detailContent,
        fileUrl: this.fileUrl, // 上传文件
        audioFileUrl: this.audioFileUrl,
      };
      this.$emit("change-value", newFeedbackItem);
    },
    onChangeFeedBackType() {
      let newFeedbackItem = {
        softWareType: this.softWareType,
        softWareTypeLabel: this.softWareTypeLabel,
        feedBackType: this.feedBackType,
        detailContent: this.detailContent,
        fileUrl: this.fileUrl, // 上传文件
        audioFileUrl: this.audioFileUrl,
      };
      this.$emit("change-value", newFeedbackItem);
    },
    onBlurDetailContent() {
      let newFeedbackItem = {
        softWareType: this.softWareType,
        softWareTypeLabel: this.softWareTypeLabel,
        feedBackType: this.feedBackType,
        detailContent: this.detailContent,
        fileUrl: this.fileUrl, // 上传文件
        audioFileUrl: this.audioFileUrl,
      };
      this.$emit("change-value", newFeedbackItem);
    },
    //#region 录音相关
    // 打开录音弹框
    onClickSoundRecord() {
      if (this.audioFileUrl) {
        return;
      }
      this.soundRecord.show = true;
    },
    // 关闭录音弹框
    onCloseSoundRecord() {
      if (this.soundRecord.recorder || this.soundRecord.recorder.duration > 0) {
        this.handleDestroy(true);
      }
      this.soundRecord.show = false;
    },
    // 销毁录音
    handleDestroy(notVerifying) {
      if (
        !notVerifying &&
        (this.soundRecord.recorder == null || this.soundRecord.recorder.duration === 0)
      ) {
        this.$toast.fail("请先录音");
        return false;
      }
      this.soundRecord.recorder.destroy(); // 销毁实例
      this.soundRecord.timer = null;
      this.soundRecord.recordingstatus = "00";
      this.audioFileUrl = "";
      this.trendsStyle();
      let newFeedbackItem = {
        softWareType: this.softWareType,
        softWareTypeLabel: this.softWareTypeLabel,
        feedBackType: this.feedBackType,
        detailContent: this.detailContent,
        fileUrl: this.fileUrl, // 上传文件
        audioFileUrl: this.audioFileUrl,
      };
      this.$emit("change-value", newFeedbackItem);
    },
    // 上传录音
    async uploadRecord() {
      if (this.soundRecord.recorder == null || this.soundRecord.recorder.duration === 0) {
        this.$toast.fail("请先录音");
        return false;
      }
      if (this.soundRecord.recordingstatus === "01") {
        this.$toast.fail("请先结束录音");
        return false;
      }
      this.handleStop(); // 停止录音
      this.soundRecord.timer = null;
      const blob = this.soundRecord.recorder.getWAVBlob();
      const newbolb = new Blob([blob], { type: "audio/wav" });
      const fileOfBlob = new File([newbolb], new Date().getTime() + ".wav");
      this.soundRecord.uploadRecordLoading = true;
      let url = await new AliOss().upload(fileOfBlob);
      if (url) {
        this.audioFileUrl = url;
        this.soundRecord.uploadRecordLoading = false;
        this.soundRecord.show = false;
      }
      let newFeedbackItem = {
        softWareType: this.softWareType,
        softWareTypeLabel: this.softWareTypeLabel,
        feedBackType: this.feedBackType,
        detailContent: this.detailContent,
        fileUrl: this.fileUrl, // 上传文件
        audioFileUrl: this.audioFileUrl,
      };
      this.$emit("change-value", newFeedbackItem);
    },
    trendsStyle() {
      this.soundRecord.isItAnimated =
        this.soundRecord.recordingstatus === "01" ? true : false;
    },
    // 开始录音
    handleStart() {
      if (this.soundRecord.recordingstatus === "01") {
        this.handleStop();
      } else {
        this.soundRecord.recorder = new Recorder({
          sampleBits: 16, // 采样位数，支持 8 或 16，默认是 16
          sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，Chrome 是 48000
          numChannels: 1, // 声道数，支持 1 或 2， 默认是 1
        });
        Recorder.getPermission().then(
          () => {
            console.log("开始录音");
            this.soundRecord.recorder.start(); // 开始录音
            this.soundRecord.recordingstatus = "01";
            this.trendsStyle();
          },
          (error) => {
            console.log(error, "error");
            if (error.message == "Permission denied") {
              this.$toast.fail("获取麦克风权限失败！");
            } else {
              this.$toast.fail("出错了！请联系管理员");
            }
          }
        );
      }
    },
    // 停止录音
    handleStop() {
      console.log("停止录音");
      this.soundRecord.recorder.stop(); // 停止录音
      this.soundRecord.recordingstatus = "02";
      this.trendsStyle();
    },
    // 播放录音
    handlePlay() {
      console.log(this.soundRecord.recorder);
      this.soundRecord.recorder.play();
      this.soundRecord.recordingstatus = "03";
      // 播放时长
      this.soundRecord.timer = setInterval(() => {
        try {
          this.soundRecord.playTime = this.soundRecord.recorder.getPlayTime();
        } catch (error) {
          this.soundRecord.timer = null;
        }
      }, 100);
    },
    //#endregion

    //#region 上传图片相关
    onBeforeRead(e) {
      let filelist = [];
      if (!e.length) {
        filelist.push(e);
      } else {
        filelist = e;
      }
      if (filelist.length > 5) {
        this.$toast.fail("最多仅支持上传5张照片！");
        return;
      }
      for (const item of filelist) {
        const ext = item.name.replace(/.*\./, "").toLowerCase();
        if (!["jpg", "png", "jpeg"].includes(ext)) {
          this.$toast.fail("所传素材格式不支持");
          return false;
        }
        if (e.size > 10 * 1024 * 1024) {
          this.$toast.fail("文件大小不能超过10M");
          return false;
        }
      }
      return true;
    },
    async onAfterRead(file) {
      let url = "";
      if (file.length > 0) {
        file.map(async (item, index) => {
          this.uploadfiles.push({
            name: item.file.name,
            type: item.file.type,
          });
          this.fileUrl[index].status = "uploading";
          this.fileUrl[index].message = "上传中...";
          url = await new AliOss().upload(file[index].file); // 调用oss上传
          if (url) {
            this.fileUrl[index].url = url;
            this.fileUrl[index].status = "done";
            this.fileUrl[index].message = "上传成功";
          }
        });
      } else {
        this.uploadfile.name = file.file.name; // 获取文件名
        this.uploadfile.type = file.file.type; // 获取类型
        this.fileUrl[this.fileUrl.length - 1].status = "uploading";
        this.fileUrl[this.fileUrl.length - 1].message = "上传中...";
        url = await new AliOss().upload(file.file); // 调用oss上传
        if (url) {
          this.fileUrl[this.fileUrl.length - 1].url = url;
          this.fileUrl[this.fileUrl.length - 1].status = "done";
          this.fileUrl[this.fileUrl.length - 1].message = "上传成功";
        }
      }
      let newFeedbackItem = {
        softWareType: this.softWareType,
        softWareTypeLabel: this.softWareTypeLabel,
        feedBackType: this.feedBackType,
        detailContent: this.detailContent,
        fileUrl: this.fileUrl, // 上传文件
        audioFileUrl: this.audioFileUrl,
      };
      this.$emit("change-value", newFeedbackItem);
    },
    onDeleteImg(file) {
      (this.fileUrl || []).filter((v) => v.url !== file.url);
      let newFeedbackItem = {
        softWareType: this.softWareType,
        softWareTypeLabel: this.softWareTypeLabel,
        feedBackType: this.feedBackType,
        detailContent: this.detailContent,
        fileUrl: this.fileUrl, // 上传文件
        audioFileUrl: this.audioFileUrl,
      };
      this.$emit("change-value", newFeedbackItem);
    },
    //#endregion
  },
};
</script>
<style lang="less" scoped>
.radioGroup /deep/ .van-radio--horizontal {
  margin-bottom: 10px;
}
.small-triangle {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #4e81ee;
  margin-right: -3px;
}

.prompt {
  display: flex;
  align-items: center;
  &-content {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: white;
    background-color: #4e81ee;
    padding: 5px 15px;
    border-radius: 5px;
  }
}

.soundRecording {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 0px 0px 50px 0px;
  &-action {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    margin-top: 15px;
  }
  .reRecord {
    display: flex;
    flex-direction: column;
    align-items: center;
    .reRecordIcon {
      border: 1px solid #ebeef5;
      padding: 10px;
      border-radius: 50%;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    }
    span {
      margin-top: 10px;
      font-size: 13px;
      color: #717171;
    }
  }
}
.recordingDuration {
  text-align: center;
  font-size: 25px;
  color: #333333;
}

@--h: 80px;
.container {
  display: flex;
  align-items: center;
  position: relative;
  height: @--h;
  justify-content: center;
}
.container-play span {
  animation: loading 1.5s infinite linear;
  animation-delay: calc(0.5s * var(--d));
}
.container-stop span {
  animation: "";
  animation-delay: "";
}
.container span {
  background: linear-gradient(to top, #727272 0%, #727272 100%);
  width: 2px;
  height: 15%;
  border-radius: calc(@--h * 0.2 * 0.5);
  margin-right: 4px;
}
.container span:last-child {
  margin-right: 0px;
}
@keyframes loading {
  0% {
    background-image: linear-gradient(to right, #727272 0%, #727272 100%);
    height: 15%;
    border-radius: calc(@--h * 0.2 * 0.5);
  }
  50% {
    background-image: linear-gradient(to top, #727272 0%, #727272 100%);
    height: 40%;
    border-radius: calc(@--h * 1 * 0.5);
  }
  100% {
    background-image: linear-gradient(to top, #727272 0%, #727272 100%);
    height: 15%;
    border-radius: calc(@--h * 0.2 * 0.5);
  }
}
</style>

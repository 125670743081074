<template>
  <div class="userFeedback">
    <van-collapse v-model="activeNames" :lazy-render="false">
      <div
        v-for="(item, index) in feedbackList"
        :key="`反馈问题${index + 1}`"
        class="collapse-item"
      >
        <van-collapse-item
          :title="`反馈问题${index + 1}`"
          :name="index"
          :lazy-render="false"
        >
          <FeedbackItem
            :ref="`refFeedbackItem${index}`"
            :feedback="item"
            @emit-ref="onEmitRef"
            @change-value="onChangeValue($event, index)"
          ></FeedbackItem>
          <template #icon>
            <van-icon
              name="arrow-down"
              style="margin-top: 6px"
              v-if="activeNames.findIndex((i) => i == index) === -1"
            />
            <van-icon name="arrow-up" style="margin-top: 6px" v-else />
          </template>
          <template #right-icon>
            <van-icon
              name="delete"
              @click.stop="onClickDeleteFeedback(index)"
              style="margin-top: 6px"
              v-if="index !== 0"
            />
            <div v-else></div>
          </template>
        </van-collapse-item>
      </div>
    </van-collapse>
    <div class="addFeedback">
      <van-button
        type="primary"
        plain
        size="small"
        color="#3277FF"
        @click="onClickAddFeedback"
        v-if="feedbackList.length < 10"
      >
        再添加一条反馈
      </van-button>
      <div v-else style="font-size: 12px; color: #ef5565; text-align: center">
        一次性最多可添加10条哦
      </div>
    </div>
    <van-form :scroll-to-error="true" :show-error="false" ref="form">
      <van-field
        v-model="linkName"
        required
        name="linkName"
        label="联系人"
        placeholder="请输入联系人"
        :rules="[{ required: true, message: '请输入联系人！' }]"
      />
      <van-field
        v-model="telNumber"
        required
        ref="telNumberField"
        name="telNumber"
        label="手机号码"
        maxlength="11"
        type="tel"
        placeholder="请输入手机号码"
        :rules="telNumberRules"
        :disabled="disabledMobile"
      />
      <van-field
        v-model="mobileVerifyCode"
        required
        center
        clearable
        label="验证码"
        name="mobileVerifyCode"
        placeholder="请输入短信验证码"
        :rules="[{ required: true, message: '请输入短信验证码！' }]"
      >
        <template #button>
          <van-button
            size="small"
            :disabled="codeDisabled"
            plain
            type="info"
            native-type="button"
            @click.stop="smsClick"
            >{{ codeText }}</van-button
          >
        </template>
      </van-field>
    </van-form>
    <div class="agree-checkbox">
      <van-checkbox :icon-size="14" v-model="agreementChecked"
        >我已阅读并同意</van-checkbox
      ><span class="xy" @click="agreementShow = true">《用户隐私协议》</span>
    </div>
    <div style="margin: 16px">
      <van-button round block type="info" :loading="submitLoading" @click="onSubmit"
        >提交</van-button
      >
    </div>
    <div style="margin: 16px" v-if="within">
      <van-button round block type="default" native-type="button" @click.stop="GoBack"
        >返回</van-button
      >
    </div>
    <!-- 弹窗同意协议 -->
    <van-dialog
      v-model="agreeConfrim"
      title="用户隐私协议"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="agree-tip">
        为了更好的保障您的合法权益，请阅读并同意以下 协议<span
          class="xy"
          @click="agreementShow = true"
          >《用户隐私协议》</span
        >，同意后 验证码将发送到您的手机。
      </div>
      <div class="agree-btn">
        <van-button type="default" @click="agreeConfrim = false">不同意</van-button>
        <van-button type="info" @click="consentAgreement">同意</van-button>
      </div>
    </van-dialog>
    <van-dialog
      v-model="errorInfo.show"
      title="错误提醒"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="error-info">
        {{ errorInfo.text }}
      </div>
      <div class="error-action">
        <van-button
          @click="errorInfo.show = false"
          class="error-action-btn"
          round
          color="linear-gradient(to right,#ff6034,#ee0a24)"
        >
          知道了
        </van-button>
      </div>
    </van-dialog>
    <van-dialog
      title="提示"
      v-model="deleteInfo.show"
      showCancelButton
      confirmButtonColor="#1989FA"
      @cancel="
        deleteInfo.show = false;
        deleteInfo.index = -1;
      "
      @confirm="onClickDeleteFeedback(deleteInfo.index)"
    >
      <div style="display: flex; margin: 16px">是否确认删除该反馈？</div>
    </van-dialog>
    <van-popup
      v-model="agreementShow"
      closeable
      position="bottom"
      :style="{ height: '100%' }"
    >
      <div class="agreement">
        <agreement-vue></agreement-vue>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import { RadioGroup, Radio, Icon, Collapse, CollapseItem, showConfirmDialog } from "vant";

import { mobile_smsCode } from "@/api/invite";
import { feedback } from "@/api/userFeedback";
import agreementVue from "./agreement.vue";
import FeedbackItem from "./components/FeedbackItem.vue";

Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Icon);
Vue.use(Collapse);
Vue.use(CollapseItem);
export default Vue.extend({
  components: { agreementVue, FeedbackItem },
  data() {
    return {
      within: false,
      telNumberRules: [
        {
          required: true,
          message: "请输入手机号码",
        },
        {
          pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
          message: "请输入正确的手机号码",
        },
      ],
      agreeConfrim: false,
      agreementShow: false,
      agreementChecked: false,
      submitLoading: false,
      codeText: "获取验证码",
      smstimer: null, // 获取验证码倒计时
      codeDisabled: false, // 获取验证码按钮Disabled状态
      softWareTypeLabel: "计价软件", // 软件反馈选择
      softWareType: "1", // 软件反馈选择
      feedBackType: "", //反馈类型
      detailContent: "", // 详情
      linkName: "", // 联系人
      telNumber: "", // 手机号码
      mobileVerifyCode: "", // 手机验证码
      audioFileUrl: "", // 录音文件地址
      fileUrl: [], // 上传文件
      soundRecordingModel: false, // 录音弹框
      showPicker: false,
      isItAnimated: false, // 是否播放动画
      activeNames: [0],
      feedbackList: [
        {
          softWareType: "1",
          softWareTypeLabel: "计价软件",
          feedBackType: "1",
          detailContent: "",
          fileUrl: [], // 上传文件
          audioFileUrl: "",
        },
      ],
      refFeedbacks: [],
      errorInfo: {
        show: false,
        text: "",
      },
      disabledMobile: false,
      deleteInfo: {
        show: false,
        index: -1,
      },
    };
  },

  created() {
    this.within = this.$route.query.within === "true" ? true : false;
    if ((this.$store.getters.currentLoginMobile ?? "") !== "") {
      this.disabledMobile = true;
      this.telNumber = this.$store.getters.currentLoginMobile;
      this.linkName = this.$store.getters.currentLoginName;
    } else {
      this.disabledMobile = false;
    }
  },
  methods: {
    // 重置表单
    resetForm() {
      this.refFeedbacks = [];
      this.feedbackList = [];
      this.$nextTick(() => {
        let newFeedbackList = [
          {
            softWareType: "1",
            softWareTypeLabel: "计价软件",
            feedBackType: "1",
            detailContent: "",
            fileUrl: [], // 上传文件
            audioFileUrl: "",
          },
        ];
        this.feedbackList = newFeedbackList;
        this.mobileVerifyCode = "";
        if ((this.$store.getters.currentLoginMobile ?? "") === "") {
          this.linkName = "";
          this.telNumber = "";
        }
      });
    },
    async onSubmit(values) {
      // 同意协议
      if (!this.agreementChecked) {
        this.agreeConfrim = true;
        return;
      }
      let errorInfo = [];
      let flag = true;
      console.log(this.refFeedbacks);
      // for (let i = 0; i < this.refFeedbacks.length; i++) {
      //   try {
      //     await this.refFeedbacks[i].validate();
      //   } catch (error) {
      //     flag = false;
      //     errorInfo.push(i + 1);
      //   }
      // }
      for (let i = 0; i < this.feedbackList.length; i++) {
        try {
          console.log(this.$refs[`refFeedbackItem${i}`]);
          await this.$refs[`refFeedbackItem${i}`][0].$refs.refFeedback.validate();
        } catch (error) {
          flag = false;
          errorInfo.push(i + 1);
        }
      }
      if (!flag) {
        this.errorInfo.text =
          errorInfo.map((item) => "问题" + item).join("、") +
          "，反馈内容不完善，请补充后再提交！";
        this.errorInfo.show = true;
        return;
      }
      this.$refs.form.validate().then((res) => {
        console.log(res);
        if (res) {
          return;
        }
        let feedBackDTOList = this.feedbackList.map((item) => {
          return {
            softWareType: item.softWareType,
            feedBackType: item.feedBackType,
            detailContent: item.detailContent,
            audioFileUrl: item.audioFileUrl,
            fileUrl: item.fileUrl
              .map((ele) => {
                return ele.url;
              })
              .join(),
            linkName: item.linkName,
            telNumber: item.telNumber,
            mobileVerifyCode: item.mobileVerifyCode,
          };
        });
        let params = {
          feedBackDTOList: feedBackDTOList,
          linkName: this.linkName,
          telNumber: this.telNumber,
          mobileVerifyCode: this.mobileVerifyCode,
        };
        this.submitLoading = true;
        feedback(params)
          .then((res) => {
            this.$toast.success("提交成功！");
            this.codeText = "获取验证码";
            this.resetForm();
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
    },
    // 软件选择
    onConfirm(val) {
      this.softWareTypeLabel = val.label;
      this.softWareType = val.value;
      this.showPicker = false;
    },
    // 同意用户隐私协议
    consentAgreement() {
      this.agreeConfrim = false;
      this.agreementChecked = true;
      this.smsClick();
    },
    // 发送验证码
    smsClick() {
      this.$refs.form.resetValidation();
      this.$refs.telNumberField.validate().then((res) => {
        if (res) {
          return;
        }
        if (!this.agreementChecked) {
          this.agreeConfrim = true;
          return;
        }
        if (!this.codeDisabled) {
          if (!this.smstimer) {
            let time = 60;
            this.codeText = `${time}秒后重新获取`;
            this.codeDisabled = true;
            this.smstimer = setInterval(() => {
              if (time > 1) {
                time = time - 1;
                this.codeText = `${time}秒后重新获取`;
              } else {
                clearInterval(this.smstimer);
                this.codeText = "重新发送";
                this.codeDisabled = false;
                this.smstimer = null;
              }
            }, 1000);
          }
          //发送验证码
          mobile_smsCode(this.telNumber).then((res) => {
            this.$toast.success(res);
          });
        }
      });
    },
    GoBack() {
      this.$router.go(-1);
    },
    onEmitRef(value) {
      // console.log(value);
      // let newRefFeedbacks = this.refFeedbacks;
      // newRefFeedbacks.push(value);
      // this.refFeedbacks = newRefFeedbacks;
    },
    onClickAddFeedback() {
      console.log(this.refFeedbacks);
      let refFeedbacks = this.$refs[`refFeedbackItem${this.feedbackList.length - 1}`][0]
        .$refs.refFeedback;
      refFeedbacks.validate().then((res) => {
        if (res) {
          return;
        }
        let newFeedbackList = JSON.parse(JSON.stringify(this.feedbackList));
        newFeedbackList.push({
          softWareType: "1",
          softWareTypeLabel: "计价软件",
          feedBackType: "1",
          detailContent: "",
          fileUrl: [], // 上传文件
          audioFileUrl: "",
        });
        this.feedbackList = newFeedbackList;
        let newActiveNames = JSON.parse(JSON.stringify(this.activeNames));
        newActiveNames.push(newFeedbackList.length - 1);
        this.activeNames = newActiveNames;
      });
    },
    onChangeValue(value, index) {
      let newFeedbackList = JSON.parse(JSON.stringify(this.feedbackList));
      newFeedbackList[index] = value;
      this.feedbackList = newFeedbackList;
    },
    onClickDeleteFeedback(index) {
      let newFeedbackList = JSON.parse(JSON.stringify(this.feedbackList));
      newFeedbackList.splice(index, 1);
      let newActiveNames = JSON.parse(JSON.stringify(this.activeNames));
      newActiveNames = newActiveNames.filter((item) => item != index);
      newActiveNames = newActiveNames.map((item) => {
        if (item > index) {
          return item - 1;
        }
        return item;
      });
      this.activeNames = newActiveNames;
      this.refFeedbacks = [];
      this.feedbackList = newFeedbackList;
      this.deleteInfo.show = false;
      this.deleteInfo.index = -1;
    },
    onClickDeleteInfo(index) {
      this.deleteInfo.index = index;
      this.deleteInfo.show = true;
    },
  },
});
</script>

<style lang="less" scoped>
.collapse-item {
  /deep/ .van-collapse-item__title {
    background-color: #edf4fe !important;
  }
  /deep/ .van-collapse-item__content {
    padding: 6px;
  }
}
.addFeedback {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}
.userFeedback {
  padding-bottom: 16px;
  .agree-checkbox {
    width: 280px;
    font-size: 13px;
    padding: 0.3rem 0.4rem;
    /deep/.van-checkbox,
    /deep/.van-checkbox__icon,
    /deep/.van-checkbox__icon .van-icon {
      display: inline;
    }
    /deep/.van-checkbox__label {
      line-height: 11px;
    }
    .xy {
      color: #ff8260;
    }
  }
}
.agreement {
  font-size: 12px;
  color: #333;
  &-title {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
  }
  &-content {
    padding: 0 15px;
  }
}
.agree-tip {
  font-size: 12px;
  padding: 20px 30px;
  color: #000;
  .xy {
    color: #ff8260;
  }
}
.agree-btn {
  /deep/.van-button {
    width: 94px;
  }
  /deep/.van-button--default {
    background: #f0f4ff;
  }
  display: flex;
  justify-content: space-between;
  padding: 0 60px;
  padding-bottom: 24px;
}
.error-info {
  font-size: 14px;
  color: #323232;
  margin: 15px;
}
.error-action {
  display: flex;
  padding: 15px;
  &-btn {
    width: 100%;
  }
}
</style>

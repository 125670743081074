/*
 * @Description: ali-oss
 * @Author: zhangguoliang
 * @Date: 2021-08-19 10:09:03
 * @LastEditors: zhangziyu
 * @LastEditTime: 2024-09-02 15:12:53
 */

import OSS, { Options } from 'ali-oss';

class AliOSS {
  options: Options = {
    accessKeyId: 'LTAI5tRZ44giMVzYQVDMQyqG',
    accessKeySecret: 'ja4gnYfXyPVsRhTlMzOCLNxexEsFFp',
    bucket: 'hzjt-yunzaojia-dev',
    secure: true,
    region: 'oss-cn-beijing',
    // accessKeyId: process.env.VUE_APP_ALI_OSS_KEY,
    // accessKeySecret: process.env.VUE_APP_ALI_OSS_SECRET,
    // bucket: process.env.VUE_APP_ALI_OSS_BUCKET,
    // secure: true,
    // region: process.env.VUE_APP_ALI_OSS_REGION,
  };
  client = new OSS(this.options);
  getDate(): string {
    const date = new Date();
    return (
      date.getFullYear() + '' + (date.getMonth() + 1) + '' + date.getDate()
    );
  }
  getRandomStr(): string {
    return Math.random().toString(36).slice(2);
  }
  async upload(file: File | null): Promise<string> {
    if (!file) return '';
    const saveAs = `${this.getDate()}/${this.getRandomStr()}-${file.name}`;
    const result: any = await this.client.multipartUpload(saveAs, file, {});
    return this.getDownloadUrl(result);
  }
  getDownloadUrl(result: any): string {
    const uploadFile = result.name;
    // 获取文件url
    const url = this.client.signatureUrl(uploadFile, {
      expires: 3600 * 1000 * 24 * 365 * 100,
      response: {
        'content-disposition': `attachment; filename=${encodeURIComponent(
          uploadFile
        )}`,
      },
    });
    return url.split('?')[0];
  }
  download(name: string): void {
    const url = this.client.signatureUrl(name, {
      expires: 3600,
      response: {
        'content-disposition': `attachment; filename=${name}`,
      },
    });
    window.location.href = url;
  }
}

export default AliOSS;

<template>
  <div class="scrollbar list-wrap h5agreement">
      <div class="model-tip">
      <h4>《隐私协议》</h4>
      <div class="agreement">
          <p class="content indent">
              云算房（以下称“本网站”或“本站”）属于河北宏盛建通信息技术有限公司（以下统称“宏盛建通”
              或
              “我们”），我们是一家致力于建设行业全过程智能化的解决方案供应商，我们一向倡导“为客户创造价值，为企业创造利益，为社会创造财富”的企业文化。我们很荣幸为用户（包括企业用户及个人用户，以下统称“您”或“用户”）
              提供宏盛建通的产品与服务。您在使用我们的服务时，我们可能会收集和使用您的相关个人信息。我们希望通过本《隐私协议》（以下统称“本协议”）向您说明我们在收集和使用您相关个人信息时对
              应的处理规则等相关事宜，以便更好地保障您的权益。
          </p>
          <p class="content indent">
              在阅读完本政策后，如您对本政策或与本政策相关的事宜有任何问题，您可通过本政策“如何联系我们”章节所列的反馈渠道联系我们，我们会尽快为您作出解答。
          </p>
          <p class="content bold">一、隐私权声明</p>
          <p class="content indent">
              宏盛建通承诺保护使用我们产品和服务用户（以下统称“用户”或“您”）个人信息和隐私安全。有时候我们需要某些信息才能为您提供您请求的服务，本隐私权声明解释了这些情况下的数据收集和使用情况。本隐私权声明适用于宏盛建通网站的所有相关服务。
          </p>
          <p class="content bold">二、我们如何收集和使用个人信息</p>
          <p class="content indent">
              1、我们将通过以下途径收集和获得您的个人信息：
          </p>
          <p class="content indent">1.1 您提供的信息：</p>
          <p class="content indent">
              （1）您在注册账号或使用我们的服务时，向我们提供的相关个人信息，例如电话号码、电子邮件等；
          </p>
          <p class="content indent">
              （2）您在使用过程中需要进行实名认证时，向我们提供的个人隐私，例如企业营业执照号、法人信息等；
          </p>
          <p class="content indent">
              （3）我们获取您的信息：您在使用我们的产品和服务时，我们收集、汇总、记录的信息，例如日志信息、位置信息、设备信息。
          </p>
          <p class="content indent">
              2、我们会出于以下目的，收集和使用您以下类型的个人信息：
          </p>
          <p class="content indent">2.1 帮助您完成注册及登录</p>
          <p class="content indent">
              为便于我们为您提供服务，您需要提供基本注册或登录信息，包括手机号码、电子邮箱地址，并创建您的账号、用户名和密码。
          </p>
          <p class="content indent">2.2 向您提供产品或服务</p>
          <p class="content indent">
              我们所收集和使用的个人信息是为您提供产品服务的必要条件，如缺少相关信息，我们将无法为您提供服务的核心内容，例如：
          </p>
          <p class="content indent">
              （1）购买相关云服务，需要您通过企业或个人实名认证，如您拒绝提供该部分信息，将无法正常我们提供的服务。
          </p>
          <p class="content indent">
              （2）在部分服务项目中，为便于向您提供凭证或服务，您将需提供收货人个人身份信息、姓名、地址、收货人、联系电话、支付状态等信息。如果您拒绝提供此类信息，我们将无法完成相关服务。
          </p>
          <p class="content indent">
              （3）当您需要我们提供特定的服务时，我们可能需要您提供必要的个人信息。例如，如果您更进一步了解我们的产品、成为我们的合作伙伴或了解更多信息，
              您应提供您的姓名、电子邮件地址、电话号码、国家、部门和职位、公司名称等信息，以确保我们能够与您联系并向您发送有关我们产品和服务的信息。
          </p>
          <p class="content indent">
              （4）当您联系我们的客服或要求我们响应服务时，我们可能需要您提供必要的个人信息以匹配并核验您的身份，以便保障您的账号及系统安全。
              我们可能还会保存您的通信/通话记录和内容或您留下的联系方式等信息，
              以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果；
          </p>
          <p class="content indent">
              （5）为向您提供服务或解决部分问题时，我们可能会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：
          </p>
          <p class="content childIndent">
              •
              日志信息：当您使用我们的服务时，我们可能会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的注册时间、登录账号、功能点击频次、打开工程次数、
              软件操作步骤（例如：触发某个功能，需要收集触发当前功能的点击频次）、界面尺寸大小（用户调整我们提供的窗口界面大小）、功能复选框勾选状态、
              错误提示弹窗、新建构件参数图的选择数据、构件属性修改值的记忆、产品使用时长、产品启动频次、搜索查询内容、IP地址、浏览器的类型、电信运营商、网络环境、使用的语言、
              访问日期和时间及您访问的网页浏览记录、Push打开记录、停留时长、刷新记录、发布记录及分享。
          </p>
          <p class="content childIndent">
              •
              设备信息：我们可能会根据您在系统使用中授予的具体权限，接收并记录您所使用的设备相关信息（设备机型、
              操作系统及版本、客户端版本、设备分辨率、包名、设备设置、进程及软件列表、唯一设备标识符、软硬件特征信息）、设备所在位置相关信息
              （例如IP 地址等）。
          </p>
          <p class="content indent">
              请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，
              则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
          </p>
          <p class="content indent">
              （6）我们通过间接方式收集到的您的个人信息：
          </p>
          <p class="content indent">
              我们可能从关联方、第三方获取您授权共享的相关信息。例如，我们可能从第三方获取您授权共享的账户信息（包括用户名、头像、昵称）
              并在您同意本政策后将您的第三方账户与您在宏盛建通注册的账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们将在符合相关法律法规规定，
              并依据与关联方或第三方合作伙伴的约定、确信其提供的信息来源合法的前提下，收集并使用您的这些信息。
          </p>
          <p class="content indent">2.3 向您提供产品或服务的信息展示和推送</p>
          <p class="content indent">
              （1）基于上述您向我们提供的信息、我们可能收集的信息及我们通过间接方式收集到的您的信息，我们可能会基于上述一项或几项信息的结合，
              进行推荐算法建模、程序化广告推荐算法建模、用户行为分析及用户画像，以便向您提供更契合您需求的页面展示和推荐个性化内容。
          </p>
          <p class="content indent">
              （2）为您展示和推送产品或服务。我们可能使用您的信息（例如您的日志信息、设备信息、位置信息、订单信息），用于提取您的偏好、行为习惯、位置信息相关特征，
              并基于特征标签通过电子邮件、短信或其他方式向您发送营销信息，提供或推广我们或第三方的产品和服务。
          </p>
          <p class="content indent">
              （3）向您发出通知。我们可能在必需时（例如当我们由于系统维护而暂停某一单项服务、变更、终止提供某一单项服务时）向您发出与服务有关的通知。
          </p>
          <p class="content indent">
              如您不希望继续接收我们推送的消息，您可要求我们停止推送，例如：根据短信退订指引要求我们停止发送推广短信，不再接收我们推送的消息；
              但我们依法律规定或单项服务的服务协议约定发送消息的情形除外。
          </p>
          <p class="content indent">2.4 改进我们的服务</p>
          <p class="content indent">
              我们可能将通过我们提供的产品与服务所收集的个人信息和业务数据，用于我们的其他服务。例如，在您使用某一项产品和服务时所收集的您的信息，
              可能在另一项产品和服务中心服务中用于向您提供特定内容或向您展示与您相关的、而非普遍推送的信息；我们可能让您参与有关我们的产品和服务的调查，
              帮助我们改善现有服务或设计新服务。
          </p>
          <p class="content indent">2.5 其他用途</p>
          <p class="content indent">
              您了解并同意，在收集您的个人信息和业务数据后，我们可能通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别您的身份，在此情况下我们有权使用已经去标识化的信息，
              对用户数据库进行分析并予以商业化的利用，而无需另行获得您的同意。
          </p>
          <p class="content indent">2.6 征得授权同意的例外</p>
          <p class="content">
              根据相关法律法规规定，以下情形中收集您的信息无需征得您的授权同意：
          </p>
          <p class="content indent">（1）与国家安全、国防安全有关的；</p>
          <p class="content indent">
              （2）与公共安全、公共卫生、重大公共利益有关的；
          </p>
          <p class="content indent">
              （3）与犯罪侦查、起诉、审判和判决执行等有关的；
          </p>
          <p class="content indent">
              （4）出于维护信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
          </p>
          <p class="content indent">
              （5）所收集的信息是您自行向社会公众公开的；
          </p>
          <p class="content indent">
              （6）从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；
          </p>
          <p class="content indent">
              （7）根据您的要求或您所属企业签订合同所必需的；
          </p>
          <p class="content indent">
              （8）用于维护宏盛建通产品和服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
          </p>
          <p class="content indent">（9）为合法的新闻报道所必需的；</p>
          <p class="content indent">
              （10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；
          </p>
          <p class="content indent">（11）法律法规规定的其他情形。</p>
          <p class="content indent">2.7 有关个人敏感信息的提示</p>
          <p class="content indent">
              以上由您提供或我们收集您的信息中，可能包含您的个人敏感信息，例如身份证件号码、手机号码、银行账号、通信记录和内容、财产信息、交易信息等。
              请您谨慎并留意个人敏感信息，您同意您的个人敏感信息我们可以按本政策所述的目的和方式来处理。
          </p>
          <p class="content indent">
              我们不收集任何关于您的特殊类别的敏感个人信息（包括您的种族或族裔、宗教或哲学信仰、性取向、政治观点、您的健康信息以及遗传和生物特征数据）。我们也不收集任何有关刑事定罪和犯罪的信息。
          </p>
          <p class="content bold">三、Cookie 的使用</p>
          <p class="content indent">
              宏盛建通使用 Cookie 来帮助您实现您的联机体验的个性化。Cookie
              是由网页服务器存放在您的硬盘中的文本文件。Cookie
              不能用来运行程序或将病毒递送到您的计算机中。指定给您的 Cookie
              是唯一的，它只能由将 Cookie 发布给您的域中的 Web 服务器读取。 Cookie
              的主要用途之一是提供一种节约时间的实用功能。例如，如果您将云算房的网页个性化，或在宏盛建通网站或服务上注册，Cookie
              会帮助宏盛建通在您后续访问时调用您的特定信息。这样可以简化记录您的个人信息（例如帐单寄送地址、首选电子邮件地址等）的流程。当您返回到宏盛建通网站时，宏盛建通可以调出您以前所提供的信息，使您能够容易地使用您自定义的云算房功能。宏盛建通允许那些在我们网页上发布广告的公司到用户电脑上设定或取用Cookie。其他公司将按其自订的隐私权政策而非本政策使用
              Cookie。宏盛建通在进行与其产品及服务相关的工作时，会使用网络beacons进入我们的网站提取
              Cookie。您可以接受或拒绝 Cookie。大多数 Web 浏览器会自动接受
              Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝
              Cookie。如果选择拒绝
              Cookie，那么您可能无法完全体验所访问的云算房服务或网站的互动功能。
          </p>
          <p class="content bold">四、我们如何储存和保护个人信息</p>
          <p class="content indent">
              我们仅在本政策所述目的所必需期间和法律法规要求的最短时限内储存您的个人信息。
          </p>
          <p class="content indent">
              我们在中华人民共和国境内运营中收集和产生的个人信息，储存在中国境内，以下情形除外：
          </p>
          <p class="content indent">（1）法律法规有明确规定；</p>
          <p class="content indent">（2）获得您的授权同意；</p>
          <p class="content indent">
              针对以上情形，我们会确保依据本政策及国家法律法规要求对您的个人信息提供足够的保护。
          </p>
          <p class="content indent">
              我们非常重视信息安全，成立了专门的安全团队，并采取一切合理可行的措施，保护您的个人信息：
          </p>
          <p class="content indent">1、数据安全技术措施</p>
          <p class="content indent">
              我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、
              修改，避免数据的损坏或丢失。我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。
          </p>
          <p class="content indent">
              我们的服务采取了多种加密技术，例如在某些产品中，我们将利用加密技术（例如SSL）来保护您的个人信息，采取加密技术对您的个人信息进行加密保存，并通过隔离技术进行隔离。
          </p>
          <p class="content indent">
              在个人信息使用时，例如信息展示、信息关联计算，我们会采用多种数据脱敏技术增强信息在使用中的安全性。
          </p>
          <p class="content indent">
              我们还会采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。
          </p>
          <p class="content indent">
              2、我们会采取一切合理可行的措施，确保未收集无关的个人信息。
          </p>
          <p class="content indent">
              3、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，
              我们建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。
          </p>
          <p class="content indent">
              4、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的个人信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，
              导致个人信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
          </p>
          <p class="content indent">5、安全事件处置</p>
          <p class="content indent">
              在通过宏盛建通产品和服务与第三方进行沟通或购买产品及服务时，您不可避免的要向交易对方或潜在的交易对方披露自己的信息，如联络方式或者邮政地址等。请您妥善保护自己的信息，仅在必要的情形下向他人提供。
          </p>
          <p class="content indent">
              在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。
              我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。
          </p>
          <p class="content indent">
              请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。
              您需要了解，您接入宏盛建通产品和服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。
          </p>
          <p class="content indent">
              请您务必妥善保管好您的账号、密码及其他身份要素。您在使用宏盛建通产品和服务时，我们会通过您的账号、密码及及其他身份要素来识别您的身份。一旦您泄露了前述信息，您可能会蒙受损失，并可能对您产生不利。
              如您发现账号、密码及/或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。
          </p>
          <p class="content bold">五、如何管理您的个人信息</p>
          <p class="content indent">
              1、根据相关数据保护法律规定，就我们所使用的您的信息，您享有以下权利：
          </p>
          <p class="content indent">（1）您有权请求访问您的信息；</p>
          <p class="content indent">
              （2）如果信息不准确或不完整，您有权请求更新或修改您的信息；
          </p>
          <p class="content indent">
              （3）
              因与您有关的特定理由，您有权反对您的信息的某些使用（包括直接营销，数据画像，基于合法利益的处理，以及用于科学或历史研究和统计的处理）；
          </p>
          <p class="content indent">
              （4）在某些情况下，您有权要求删除您的信息或限制信息的使用：
          </p>
          <p class="content childIndent">
              • 如果我们处理个人信息的行为违反法律法规；
          </p>
          <p class="content childIndent">
              • 如果我们收集、使用您的个人信息，却未征得您的同意；
          </p>
          <p class="content childIndent">
              • 如果我们处理个人信息的行为违反了与您的约定；
          </p>
          <p class="content childIndent">• 如果我们终止服务及运营。</p>
          <p class="content indent">
              2、若我们响应您的删除请求，我们不能保证立即从备份系统中删除相应的信息，但会在备份系统更新时删除这些信息。
          </p>
          <p class="content indent">3、注销</p>
          <p class="content indent">
              在符合宏盛建通产品和服务的服务协议约定条件及国家相关法律法规规定的情况下，您的该项宏盛建通产品和服务的帐号可能被注销或删除。当帐号注销或被删除后，与该帐号相关的、该单项服务项下的全部服务资料和数据将依照单项服务的服务协议约定删除或处理，但法律法规另有规定的除外。账户一旦被注销将不可恢复，
              请您在操作之前自行备份账户相关的所有信息和数据。注销账户后，您将无法再使用本账户，也将无法找回您账户中及与账户相关的任何内容或信息。
          </p>
          <p class="content bold">六、我们对未成年人的政策</p>
          <p class="content indent">
              为了保护未成年人的隐私和安全，我们对未成年人的个人信息提供了具体的保护。我们不对16周岁以下的未成年人提供产品或服务，
              并且我们不会故意或在明确知道的情况下收集未成年的个人信息。如果您未满16周岁，除非您的父母提供明确同意且该等同意可被证实，否则您将不被允许使用我们的产品或创建属于您的账号。
          </p>
          <p class="content bold">七、通知和修订</p>
          <p class="content indent">
              为了给您提供更好的服务，我们可能会根据产品或服务的更新情况及法律法规的相关要求适时修改本政策的条款，
              该等修改构成本政策的一部分。如该等更新造成您在本政策下权利的实质减少或重大变更，我们将在本政策生效前通过网站公告、推送通知、弹窗提示或其他方式来通知您，
              您如果不同意该等变更，可以选择停止使用我们的产品和服务；如您仍然继续使用，即表示您已充分阅读、理解并同意受经修订的本政策的约束。
          </p>
          <p class="content bold">八、法律适用与管辖</p>
          <p class="content indent">
              宏盛建通的住所地为石家庄市新华区，如果用户与宏盛建通发生争议，用户同意将争议提交至宏盛建通住所地新华市有管辖权的人民法院通过诉讼的方式解决。
          </p>
          <p class="content indent">
              本政策的执行、解释及争议的解决均适用中华人民共和国法律，且不考虑任何冲突法。
          </p>
          <p class="content indent">
              如本协议条款中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议条款的其余条款仍应有效且具有约束力，并且努力使该规定反映之意向具备效力。
          </p>
          <p class="content indent">
              如您没有另外单独与宏盛建通签订服务协议，本服务条款构成您与宏盛建通之全部协议，规范您对本服务之使用。在您使用相关服务、或使用第三方提供的内容或软件时，亦应遵从所适用之附加条款及权利。
          </p>
          <p class="content bold">九、联系方式:</p>
          <p class="content indent">公司：河北宏盛建通信息技术有限公司</p>
          <p class="content indent">
              地址：河北省石家庄市桥西区新华路296 号盛安大厦6 楼
          </p>
          <p class="content indent">邮编：050011</p>
          <p class="content indent">客服电话：400-005-8008</p>
          <p class="content indent">电子信箱：hsjt@hebhsjt.com</p>
      </div>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style>
  .h5agreement {
      font-family: Source Han Sans CN;
      margin: 0;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5;
      background-color: #fff;
      font-feature-settings: 'tnum';
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
  }
  .list-wrap {
      height: 100vh;
  }
  .model-tip {
      padding: 10px 26px;
      /* overflow-y: auto; */
  }
  .model-tip h4 {
    font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
  }
  .model-tip p {
      margin-bottom: 6px;
  }
  .model-tip .bold {
      font-weight: bold;
      padding-top: 10px;
  }
  .model-tip .indent {
      text-indent: 2em;
  }
  .model-tip .childIndent {
      text-indent: 4em;
  }
</style>